import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Extra Item", "width": 550 } }, [_c("select-input", { key: "CountryId", attrs: { "label": "Country", "reference": "web-analytics.common.ppv-countries", "placeholder": "Select Country", "source": "countryId", "source-label": "countryCode", "value": _vm.country, "rules": "required", "disabled": true, "span": 4, "form-item": "", "on-select-change": _vm.onCountryChange } }), _c("text-input", { key: "Item", attrs: { "label": "Item Code", "placeholder": "Type Item Code", "rules": "required", "form-item": "", "span": 10 } }), _c("text-input", { key: "ItemDesc", attrs: { "label": "Item Desc", "placeholder": "Type Item Desc", "rules": "required", "form-item": "", "span": 10 } }), _c("select-input", { key: "ItemClass", attrs: { "label": "Item Class", "data-source": _vm.itemClassList, "placeholder": "Select Item Class", "source": "itemClass", "source-label": "itemClassDesc", "rules": "required", "span": 12, "form-item": "" } }), _c("select-input", { key: "SubClassKey", attrs: { "label": "Sub Class", "data-source": _vm.subClassList, "placeholder": "Select Country", "source": "subClass", "source-label": "subClassDesc", "rules": "required", "span": 12, "form-item": "" } })], 1);
};
var staticRenderFns$1 = [];
var CreateExtraItems_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateExtraItems",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      subClassList: [],
      itemClassList: [],
      country: this.crud.getQueryString("CountryID").value
    };
  },
  created() {
    this.getSubClassList();
    this.getItemClassList();
    this.storeValue("CountryId", this.country);
  },
  methods: {
    getSubClassList() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-sub-class?CountryId=${this.country}`).then((res) => {
        let { data: subClassList } = res;
        subClassList = [...subClassList];
        this.subClassList = subClassList;
      });
    },
    getItemClassList() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-item-class?CountryId=${this.country}`).then((res) => {
        let { data: itemClassList } = res;
        itemClassList = [...itemClassList];
        this.itemClassList = itemClassList;
      });
    },
    onCountryChange(value) {
      this.country = value;
      this.getSubClassList();
      this.getItemClassList();
    },
    storeValue(resourceKey, value) {
      const createForm = this.createForm;
      this.createForm = {
        ...createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "54c60946", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateExtraItems = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.ppv-countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppv-item-class", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppv-sub-class", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.extra-items", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/extra-items" } }, [_c("create-extra-items")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateExtraItems
  },
  data() {
    return {
      CreateExtraItems,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
